
#home {
    position: relative;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  
  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
  
    padding: 6rem 1rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 950px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
    }
  }
  
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 950px) {
      width: 100%;
      margin-right: 0rem;
    }
  }
  
  .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    .badge-cmp,
    .tag-cmp {
      padding: 0.5rem 2rem;
      border-radius: 15px;
      flex-direction: row;
      width: auto;
  
      box-shadow: 0px 0px 10px var(--secondary-color);
    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 2rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: right;
        @media screen and (max-width: 800px) {
            font-size: 0.8rem;
          }
      }
    }
  
    span {
      font-size: 2.5rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 950px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
  
      background: rgba(0, 0, 0, 0.267);
      box-shadow: 0px 0px 10px var(--secondary-color);
  
      img {
        filter: opacity(0.5) drop-shadow(0 0 0 #ffffff) drop-shadow(0 0 0 #ffffff) ;
        width: 60%;
        height: 60%;
      }
    }
  
    // circles with different height & width
  
    div:nth-child(1) {
      width: 100px;
      height: 100px;
    }
  
    div:nth-child(2) {
      margin: 1.75rem;
      width: 150px;
      height: 150px;
    }
  
    div:nth-child(3) {
      width: 70px;
      height: 70px;
    }
  
    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: 400px;
        height: 400px;
      }
  
      div:nth-child(3) {
        width: 170px;
        height: 170px;
      }
  
      div:nth-child(1) {
        width: 200px;
        height: 200px;
      }
    }
  
    @media screen and (max-width: 950px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
  
      
    }
  }
  
  .app__header-img {
    flex: 1;
    height: 100%;
  
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
  
    img {
      width: 90%;
      padding: 3rem;
      padding-top: 1rem;
      object-fit: contain;
      z-index: 1;
      margin: auto;
      @media screen and (max-width: 950px){
          width: 70%;
          margin:0 auto;
      }
      @media screen and (max-width: 750px){
        width: 70%;
        margin: 0 auto;
    }
    @media screen and (max-width: 650px){
        width: 85%;
        margin: 0 auto;
    }
    @media screen and (max-width: 550px){
        width: 80%;
        margin: 0 auto;
    }
    }
  
    .overlay_circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 90%;
    }
  
  }
