.app__navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(0, 0, 0, 0.267);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    z-index: 2;
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    img{
        width: 90px;
    }
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    li{
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;
        div{
            width: 40px;
            height: 2px;
            background: transparent;
            margin-top: 10px;
            transition: all 0.4s linear;
        }

        a{  
            padding: 0 1rem;
            color: var(--gray-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.4s linear;

            &:hover{
                color: white;
                border: 1px solid var(--secondary-color);
                border-radius: 25px;
                box-shadow: 0px 0px 7px 2px var(--secondary-color);
            }
        }
        &:hover{
            div{
                background: var(--secondary-color);
            }
        }
    }
    @media screen and (max-width:800px) {
        display: none;
    }
}

.app__navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    transition: all 0.4s linear;
    svg{
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }
    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 100%;
        height: 120vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background-image: url(../../assets/Sprinkle.svg);
        background-color: #000000;
        background-size: cover;
        box-shadow: 0 0 20px var(--secondary-color);
        svg{
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            li{
                margin: 1rem;
                a{
                    padding: 0 1rem;
                    text-decoration: none;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    color: var(--gray-color);
                    font-weight: 700;
                    transition: all 0.4s linear;
                    &:hover{
                        color: white;
                        border: 1px solid var(--secondary-color);
                        border-radius: 25px;
                        box-shadow: 0px 0px 7px 2px var(--secondary-color);
                    }
                }
            }
        }
    }
    @media screen and (min-width:800px) {
        display: none;
    }
}