input:focus, textarea:focus, select:focus{
    outline: none;
}

.app__footer-head{
    flex: 1;
    flex-direction: row;
    @media screen and (max-width:880px) {
        flex-direction: column
    }
}

.app__footer-head-connect{
    margin: 1rem;
    margin-bottom: 0rem;
    img{
        width: 230px;
        height: 200px;
        @media screen and (max-width:880px) {
            width: 180px;
            height: 150px;
        }
    }
}

.app__footer-contact{
    width: 300px;
    height: 400px;
    padding: 2rem;
    padding-bottom: 0;
    background: #6C63FF;
    margin: 0 1rem;
    margin-top: 3rem;
    flex-direction: column;
    border-radius: 15px;
    transition: all 0.8s ease-in;
    &:hover{    
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.315);
    }
    textarea{
        padding: 0.2rem;
        resize: none;
        text-align: center;
        width: 100%;
        height: 100%;
        color: #000000;
        border-radius: 20px;
        font-family: var(--font-base);
        
    }
}

.app__footer-contact-form{
    margin: 1rem;
    width: 100%;
    flex-direction: column;
    input{
        color: #000000;
        text-align: center;
        border: none;
        border-radius: 20px;
        width: 100%;
        font-family: var(--font-base);
        padding: 0.5rem;
        &:focus{
            border: none;
        }
        margin:1rem ;
    }
}

.primary-btn{
    letter-spacing: 0.2rem;
    cursor: pointer;
    border: none;
    background-color: #6C63FF;
    margin:1rem 0;
    width: 100px;
    height: 50px;
}