

.app__skills{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.app__skill-item{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.5rem;
    @media screen and (max-width:370px) {
        width: 120px;
        height: 120px;
    }
    img{
        padding: 1rem;
        background: var(--secondary-color);
        width: 70%;
        height: 70%;
        border-radius: 15px;
        object-fit: contain;
        transition: all 1.3s ease;
        &:hover{
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.212);;
        }
        
    }
    @media screen and (min-width:2000px) {
        width: 370px;
        margin: 2rem 4rem;
        img{
            height: 320px;
        }
    }
    @media screen and (max-width:400px) {
        margin: 0.5rem;
    }
}