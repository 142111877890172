.app__work{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-portfolio{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item{
        width: 300px;
        flex-direction: column;
        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: var(--secondary-color);
        cursor: pointer;
        transition: all 1.3s ease;

        &:hover{
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.212);
        }
        @media screen and (min-width:2000px) {
            width: 500px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }
        @media screen and (max-width:300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.app__work-img{
    width: 100%;
    height: 230px;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }
    @media screen and (min-width:2000px) {
        height: 350px
    }
}

.app__work-hover{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    opacity: 1;
    transition: all 0.3s ease;
    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        margin: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        svg{
            height: 50%;
            width: 50%;
        }
    }
}

.app__work-content{
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    h4{
        margin-top: 1rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 700;
    }
    p{
        line-height: 1.2rem;
        word-spacing: 0.3rem;
    }
}