.app__messages{
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3rem;
    padding: 1rem;
    input{
        color: black;
        padding: 1rem;
        margin: 1rem;
        width: 100%;
    }
}

.app__messages-display{
    align-items: center;
    justify-content: center;
    margin: 3rem;
    padding: 1rem;
    flex-direction: column;
}

.app__messages-messages{
    margin: 2rem 0;
    @media  screen and (max-width:800px) {
        flex-direction: column;
    }
}
.app__messages-message{
    flex-direction: column;
    margin: 1rem;
    background: rgba(0, 0, 0, 0.425);
    box-shadow:0 0 20px rgba(255, 255, 255, 0.281);
    @media  screen and (max-width:500px) {
        flex-direction: column;
    }
}

.app__messages-detail{
    width: 100%;
    padding: 0.5rem 1rem;
    margin: 1rem;
    margin-top: 0;
    background: #881088;
    h4{
        font-size: 1.5rem;
        margin-right: 1rem;
    }
}

.body{
    padding: 1rem;
}